import styled from '@emotion/styled'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { mq } from './../utility/breakpoint'
import { navigate } from 'gatsby'
import { LoadingIndicator } from '../components/atoms/loadingIndicator'
import { AppointmentService, TopicsService } from './../api'

import { PageContainer } from '../components/organisms/pageContainer'
import { PageContainerOuter } from '../components/organisms/pageContainerOuter'

import { useTranslation } from 'react-i18next'
import { Button, Heading, Paragraph } from '../components'
import {
  AppointmentForm,
  AppointmentFormSent,
} from '../components/organisms/appointmentForm'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { useModalContext } from '../utility'
const SalesformPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const { t } = useTranslation(`appointment`)
  const appointmentFormRef = React.useRef()
  const [loaded, setLoaded] = React.useState(false)
  const { dispatch: modalDispatch } = useModalContext()
  const [topics, setTopics] = React.useState()
  const [status, setStatus] = React.useState('')

  /* const loadTopics = async () => {
    const _topics = await TopicsService.getTopics()
    setTopics(_topics)
  }
  React.useEffect(() => {
    loadTopics()
  }, []) */

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const sendForm = data => {
    AppointmentService.sendForm(data).then(({ status, data }) => {
      if (!status) {
        setStatus('error')
      } else {
        if (data.registered) {
          navigate(`/${lang}/request-sent`)
        } else {
          navigate(`/${lang}/verification-sent`)
        }
      }
      //navigate(`/${lang}/request-sent`)
    })
  }

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en${document.location.search}`)
    }

    return <></>
  }

  return (
    <PageFrame {...params} hideMenu={true} pageName="index">
      <PageContainerOuter>
        <PageContainer>
          <StartTeaser>
            <StartTeaserColumn>
              <img src="/assets/appointment-visual.jpg" />
            </StartTeaserColumn>
            {status === '' && (
              <StartTeaserColumn>
                <Heading type="h2">{t(`start.header`)}</Heading>
                <Paragraph>{t(`start.copy`)}</Paragraph>
              </StartTeaserColumn>
            )}

            {status === 'error' && (
              <StartTeaserColumn>
                <Heading type="h2">{t(`error.header`)}</Heading>
                <Paragraph>{t(`error.copy`)}</Paragraph>{' '}
                <Button onClick={() => setStatus('')}>{t(`error.cta`)}</Button>
              </StartTeaserColumn>
            )}
          </StartTeaser>
          <Spacer />
          {status === '' && (
            <AppointmentForm onSubmit={data => sendForm(data)} />
          )}
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default SalesformPage

const StartTeaser = styled.div(
  () => `

  ${mq[3]}{
    display:flex;
    gap:130px
  }
  `
)
const StartTeaserColumn = styled.div(
  () => `flex:1;
  img{
    width:100%;
    ${mq[3]}{ width:80%; };
  }`
)
const Spacer = styled.div`
  margin: 95px 0;
  ${mq[3]} {
    margin: 125px 0;
  }
`
